import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import styled, { keyframes } from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"

//Libraries
import { Trans, useTranslation } from "react-i18next"

// Components
import Container from "components/container/"
import Observer from "components/observer/"
import Modal from "components/modal/"
import Lottie from "components/lottie/"

// Icons
import Asterisk from "assets/icons/icon-asterisk.inline.svg"
import IndiDictionary from "assets/icons/icon-indi-dictionary.inline.svg"
import Discover from "assets/icons/services/icon-discover.inline.svg"
import Strategize from "assets/icons/services/icon-strategize.inline.svg"
import Build from "assets/icons/services/icon-build.inline.svg"
import Iterate from "assets/icons/services/icon-iterate.inline.svg"

// Animations
import HumanCenteredAnimation from "assets/animations/services/processes-animation.json"

const StyledContainer = styled(Container)`
  max-width: 800px;

  ${breakpoint.medium`
    padding: 0;
  `}
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledProcesses = styled.section`
  position: relative;
  padding: 80px 0 40px 0;
  background-color: ${colors.cream};

  ${breakpoint.medium`
    padding: 160px 0 120px 0;
  `}

  .subtitle {
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
  }

  h2 {
    max-width: 580px;
    font-weight: 400;

    span {
      width: 17px;
      height: 17px;
      position: relative;
      top: -12px;
      display: inline-block;
      margin-right: 4px;

      button {
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        color: ${colors.indiblue};
        font-size: 42px;
        line-height: 22px;
        animation: ${rotate} 3s linear infinite;
        cursor: pointer;

        ${breakpoint.medium`
          top: -8px;
        `}

        svg {
          width: 100%;
          height: auto;

          * {
            fill: ${colors.indiblue};
          }
        }
      }
    }
  }

  .processes__line {
    display: none;

    ${breakpoint.medium`
      width: 4px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(((100vw - 800px) / 2) - 50px);
      display: block;
      
      .progress {
        width: 100%;
        height: 0;
        background-color: ${colors.white};
      }
    `}

    ${breakpoint.large`
      left: calc(((100vw - 800px) / 2) - 122px);
    `}
  }
`

const CardsWrapper = styled.div`
  margin-top: 48px;

  ${breakpoint.small`
    margin-top: 72px;
  `}
`

const StyledCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto 40px auto;
  opacity: ${props => (props.visible ? "1" : "0.5")};
  transition: all 0.6s;

  ${breakpoint.small`
    max-width: 800px;
    min-height: 386px;
  `}

  ${breakpoint.medium`
    position: relative;
    flex-wrap: nowrap;
  `}

  .card {
    width: 100%;
    padding: 32px 24px;
    background-color: ${colors.white};
    font-size: 18px;

    ${breakpoint.medium`
      width: calc(100% - 384px);
      flex-shrink: 0;
      padding: 48px 32px;
    `}

    .subtitle {
      color: ${colors.indiblue};
      font-size: 16px;
    }

    .title {
      margin-bottom: 8px;
      font-size: 24px;
      font-weight: 500;
    }
  }

  .photo {
    flex-shrink: 0;
    width: 100%;
    height: 384px;

    ${breakpoint.medium`
      max-width: 384px;
      width: 50%;
      height: initial;
    `}
  }

  .card__icon {
    display: none;

    ${breakpoint.medium`
      width: 96px;
      height: 96px;
      position: absolute;
      top: 24px;
      left: -80px;
      display: flex;
    `}

    ${breakpoint.large`
      left: -166px;
    `}
  }
`

const ModalContainer = styled(Container)`
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  ${breakpoint.small`
    height: auto;
  `}

  ${breakpoint.medium`
    position: relative;
  `}
`

const StyledModal = styled(Modal)`
  .modal__image {
    order: 0;
    width: 100%;
    margin-bottom: 24px;
    text-align: center;

    ${breakpoint.small`
      order: 1;
      width: calc(100% - 672px);
      margin-bottom: 0;
    `}

    img {
      max-width: 85%;

      ${breakpoint.small`
        max-width: 100%;
      `}
    }
  }

  .modal__content {
    max-width: 640px;
    order: 1;
    margin-bottom: 64px;

    ${breakpoint.small`
      order: 0;
    `}

    ${breakpoint.medium`
      margin-bottom: 0;
    `}

    h1 {
      color: ${colors.indiblue};
      font-family: ${fonts.primary};
      font-weight: 500;
    }

    .subtitle {
      color: ${colors.indiblue};
      font-family: ${fonts.primary};
      font-weight: 400;
      text-transform: lowercase;

      b {
        font-weight: 700;
      }
    }

    p {
      max-width: 544px;

      ${breakpoint.medium`
        font-size: 24px;
      `}
    }

    .dictionary {
      margin-top: 64px;

      ${breakpoint.large`
        position: absolute;
        left: 0;
        bottom: -120px;
        margin: 0;
      `}
    }
  }
`

class Heading extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
  }

  closeModal = () => {
    if (this.state.showModal) {
      this.setState(
        {
          showModal: false,
        },
        () => {
          document.querySelector("html").classList.remove("no-scroll")
          document.querySelector("body").classList.remove("no-scroll")
        }
      )
    }
  }

  openModal = event => {
    if (!this.state.showModal) {
      this.setState(
        {
          showModal: true,
        },
        () => {
          document.querySelector("html").classList.add("no-scroll")
          document.querySelector("body").classList.add("no-scroll")
        }
      )
    }
  }

  render = () => (
    <React.Fragment>
      <p className="subtitle paragraph--small">
        <Trans>Tailored process with tested tools</Trans>
      </p>
      <h2>
        <Trans>Human-centered</Trans>
        <span>
          <button type="button" onClick={this.openModal}>
            <Asterisk />
          </button>
        </span>{" "}
        <Trans>processes</Trans>{" "}
        <i>
          <Trans>a la</Trans>
        </i>{" "}
        Indicius
      </h2>

      <StyledModal
        visible={this.state.showModal}
        closeFunction={this.closeModal}
      >
        <ModalContainer>
          <div className="modal__content">
            <h1>
              <Trans>Human centered design</Trans>
            </h1>
            <p className="subtitle">
              <i>
                <Trans>[we-care-about-everyone]</Trans>
              </i>{" "}
              <b>
                <Trans>noun.</Trans>
              </b>
            </p>
            <br />
            <p>
              <Trans>
                An approach to design that focuses on users and their needs and
                requirements, taking human factors into account. Human-centered
                design notably improves people’s lives, experience and
                wellbeing.
              </Trans>
            </p>

            <div className="dictionary">
              <IndiDictionary />
            </div>
          </div>
          <div className="modal__image">
            <Lottie
              loop
              autoplay={this.state.showModal}
              animation={HumanCenteredAnimation}
            />
          </div>
        </ModalContainer>
      </StyledModal>
    </React.Fragment>
  )
}

class Card extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isVisible: false,
    }
  }

  handleVisibility = () => {
    this.setState({
      isVisible: true,
    })
  }

  render = props => (
    <Observer onVisible={this.handleVisibility} threshold="0.5">
      <StyledCard visible={this.state.isVisible}>
        <div className="card">
          <p className="subtitle">{this.props.subtitle}</p>
          <br />
          <p className="title">{this.props.title}</p>
          <p>{this.props.description}</p>
        </div>
        <BackgroundImage
          className="photo"
          fluid={this.props.image}
          style={{
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
        <div className="card__icon">{this.props.icon}</div>
      </StyledCard>
    </Observer>
  )
}

const Processes = () => {
  const {t} = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      discoverImage: file(
        relativePath: { eq: "services/processes-image-1.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      strategizeImage: file(
        relativePath: { eq: "services/processes-image-2.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      buildImage: file(relativePath: { eq: "services/processes-image-3.png" }) {
        childImageSharp {
          fluid(maxWidth: 384, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      iterateImage: file(
        relativePath: { eq: "services/processes-image-4.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 384, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <StyledProcesses id="processes">
      <div className="processes__line">
        <div id="processesProgress" className="progress"></div>
      </div>
      <StyledContainer>
        <Heading />

        <CardsWrapper>
          <Card
            subtitle={t("Discover and Research")}
            title={t("Who are you? And who are your users?")}
            description={t("Human-centered processes mean each project is different. Don’t be shy, we’ll be asking lots of questions about your project and goals. We dive deep to understand who you are, who your customers are, and what they desire.")}
            image={data.discoverImage.childImageSharp.fluid}
            icon={<Discover />}
          />

          <Card
            subtitle={t("Strategize")}
            title={t("Design the possible solutions")}
            description={t("We analyze the best process and tools for the task at hand. We pull from familiar frameworks and tools, and then tailor each project to fit you and your users’ needs.")}
            image={data.strategizeImage.childImageSharp.fluid}
            icon={<Strategize />}
          />

          <Card
            subtitle={t("Build")}
            title={t("We follow an agile workflow")}
            description={t("Pivoting is key to allow our solutions to evolve over time. We aim to eliminate the separation between designers and developers to create agile, interdisciplinary teams and deliver innovative design experiences.")}
            image={data.buildImage.childImageSharp.fluid}
            icon={<Build />}
          />

          <Card
            subtitle={t("Iterate")}
            title={t("Learn & Repeat")}
            description={t("We’ve evolved from measuring success by “we built something“ to “we made a user more successful”. Insights from user testing and research are how we know if something works, and if we’ve reached a project’s objective.")}
            image={data.iterateImage.childImageSharp.fluid}
            icon={<Iterate />}
          />
        </CardsWrapper>
      </StyledContainer>
    </StyledProcesses>
  )
}

class ProcessesWrapper extends React.Component {
  componentDidMount = () => {
    document.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount = () => {
    document.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    const scrollPosition = window.scrollY
    const processesSection = document.getElementById("processes")
    const progressElement = document.getElementById("processesProgress")

    if (
      scrollPosition >=
      processesSection.offsetTop - window.innerHeight + 64
    ) {
      progressElement.style.height =
        scrollPosition -
        (processesSection.offsetTop - window.innerHeight + 64) +
        "px"
    }
  }

  render = () => <Processes />
}

export default ProcessesWrapper
