import React from "react"

// Libraries
import styled from "styled-components"
import { Link } from "gatsby-plugin-react-i18next"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Icons
import Icon from "assets/icons/services/icon-brandnarrative.inline.svg"

const StyledEntryPoint = styled.section`
  width: 100%;
  padding: 15px 18px 15px 14px;
  border 1px solid ${colors.cloudy};
  // margin-bottom: 74px;
  
  ${breakpoint.medium`
    padding: 24px 32px;
    // margin-bottom: 116px;
  `}

  .text{
    font-size: 18px;
    line-height: 26px;
    max-width: 180px;
    width: 100%;

    ${breakpoint.medium`
      font-size: 24px;
      line-height: 28px;
      max-width: 240px;
    `}
  }

  a {
    transition: all 0.3s ease
  }
`

const ServiceEntryPoint = ({ title, text_link, to }) => {
  return (
    <StyledEntryPoint className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <Icon className="me-2 me-sm-4" />
        <div>
          <p className="text">
            {/* Let’s take your brand
            <br />
            to the next level */}
            {title}
          </p>

          <Link
            // to="/services/brand-narrative-workshop"
            to={to}
            className="size--small d-sm-none color--indiblue color-hover--midnight"
          >
            {/* Brand Narrative Workshop */}
            {text_link}
          </Link>
        </div>
      </div>

      <Link
        to={to}
        className="size--small d-none d-sm-inline-block color--indiblue color-hover--midnight"
      >
        {text_link}
      </Link>
    </StyledEntryPoint>
  )
}

export default ServiceEntryPoint
