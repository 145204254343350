import React from "react"

// Utils
import { colors } from "utils/variables/"

// Libraries
import { graphql } from "gatsby"
import { useTranslation } from "react-i18next"

// Layout
import Layout from "layouts/layout-primary"

// Context
import ContextConsumer from "components/context/"

// Components
import Seo from "components/seo/"

// Page Sections
import Hero from "sections/services/hero"
import WhatWeDo from "sections/services/what-we-do"
import Processes from "sections/services/processes"

const Services = () => {
  const { t } = useTranslation()
  return (
    <Layout menuColor={colors.midnight}>
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (data.darkMenu) {
            set({ darkMenu: false })
          }
          if (!data.showFooter) {
            set({ showFooter: true })
          }
          if (data.simplifiedFooter) {
            set({ simplifiedFooter: false })
          }
        }}
      </ContextConsumer>
      <Seo
        title={t("Services - Indicius Design Agency")}
        description={t(
          "We have a uniquely collaborative approach to solving design challenges. Branding solutions, co-creation workshops like Design Sprints, beautiful interfaces and performant front-end development."
        )}
      />
      <Hero />
      <WhatWeDo />
      <Processes />
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Services
