import React from "react"
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

//Libraries
import { Trans, Link } from "gatsby-plugin-react-i18next"

// Components
import Container from "components/container/"
import Lottie from "components/lottie-observed/"

// Animations
import Branding from "assets/animations/services/branding-animation.json"
import CoCreation from "assets/animations/services/co-creation-animation.json"
import Interfaces from "assets/animations/services/interfaces-animation.json"
import Frontend from "assets/animations/services/frontend-animation.json"
import ServiceEntryPoint from "components/service-entry-point"
import { useTranslation } from "react-i18next"

const StyledWhatWeDo = styled.section`
  width: 100%;
  padding: 80px 0;

  ${Container} {
    // max-width: 926px;
  }

  ${breakpoint.medium`
    padding: 124px 0 185px 0;
  `}

  ${breakpoint.large`
    padding: 160px 0;
  `}

  .title {
    margin-bottom: 80px;
  }

  .service-wrapper {
    margin-bottom: 80px;

    ${breakpoint.medium`
      margin-bottom: 200px;
    `}
  }

  .entry-point-wrapper {
    margin-top: 40px;

    ${breakpoint.medium`
      margin-top: 80px;
    `}
  }
`

const StyledService = styled.div`
  position: relative;
  margin-bottom: 80px;

  &:nth-child(3) {
    margin-bottom: 60px;

    ${breakpoint.medium`
      margin-bottom: 66px;
    `}
  }

  &:last-child {
    margin: 0;
  }

  ${breakpoint.medium`
    display: flex;
    justify-content: space-between;
    margin-bottom: 160px;
  `}

  .service__icon {
    width: 96px;
    flex-shrink: 0;
    display: flex;
    margin-bottom: 1em;

    ${breakpoint.medium`
      width: 140px;
      // height: 100%;
      margin: 0 80px 0 0;
    `}

    ${breakpoint.large`
      height: auto;
    `}

    svg {
      height: auto !important;
      display: block;
    }
  }

  .service__content {
    ${breakpoint.medium`
      width: calc(100% - 220px);
    `}

    ${breakpoint.large`
      width: auto;
    `}
  }

  h3 {
    font-weight: 500;
  }

  .more-info {
    font-size: 18px;

    .strikethrough {
      width: auto;
      display: inline-block;
      color: ${colors.indiblue};

      &::before {
        background-color: ${colors.supernova};
      }

      &:hover {
        color: ${colors.stout};
      }
    }
  }
`

const WhatWeDo = () => {
  const { t } = useTranslation()
  return (
    <StyledWhatWeDo>
      <Container>
        <div className="row">
          <div className="title col-12">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-10 col-md-6">
                <h4 className="mb-2 text-uppercase font-weight--700">
                  <Trans>What we do</Trans>
                </h4>
                <h1>
                  <Trans>Our bread and butter</Trans>
                </h1>
              </div>
            </div>
          </div>

          <div className="service-wrapper col-12 col-sm-10 offset-sm-1">
            <StyledService>
              <div className="service__icon">
                <Lottie animation={Branding} />
              </div>
              <div className="service__content">
                <h3>
                  <Trans>Branding solutions</Trans>
                </h3>
                <br />
                <p>
                  <Trans>
                    Productive, sustainable interactions between your brand and
                    your customers comes with establishing relationships and
                    creating an overall brand experience.
                  </Trans>
                </p>
                <br />
                <div className="more-info">
                  <p className="paragraph--small">
                    <Trans>View this service in action:</Trans>
                  </p>
                  <ul>
                    <li>
                      <Link className="strikethrough" to="/work/acgen">
                        <Trans>ACGEN Case Study</Trans>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </StyledService>
          </div>

          <div className="col-12 col-sm-10 offset-sm-1">
            <StyledService>
              <div className="service__icon">
                <Lottie animation={CoCreation} />
              </div>
              <div className="service__content">
                <h3>
                  <Trans>Co-creation workshops</Trans>
                </h3>
                <br />
                <p>
                  <Trans>We work </Trans>
                  <i>
                    <Trans>with</Trans>{" "}
                  </i>
                  <Trans>
                    you. We facilitate workshops including Brand Narrative
                    Workshops, Design Sprints, Lightning Decision Jams, and
                    custom workshops. We know from experience that involving
                    stakeholders and real users in the design process can
                    produce amazing results.
                  </Trans>
                </p>
              </div>
            </StyledService>
          </div>

          <div className="entry-point-wrapper service-wrapper col-12 col-sm-10 offset-sm-1">
            <ServiceEntryPoint
              title={t("Let's take your brand to the next level")}
              text_link={t("Brand Narrative Workshop")}
              to={"/services/brand-narrative-workshop"}
            />
          </div>

          <div className="service-wrapper col-12 col-sm-10 offset-sm-1">
            <StyledService>
              <div className="service__icon">
                <Lottie animation={Interfaces} />
              </div>
              <div className="service__content">
                <h3>
                  <Trans>Interfaces</Trans>
                </h3>
                <br />
                <p>
                  <Trans>
                    We’re known for being amazing visual creators. But
                    interfaces aren’t just canvases for pretty designs to post
                    to Dribbble. We research and test to understand users’
                    needs. Our specialties: UI/UX design, motion design, custom
                    illustrations, design systems.
                  </Trans>
                </p>
                <br />
                <div className="more-info">
                  <p className="paragraph--small">
                    <Trans>View this service in action:</Trans>
                  </p>
                  <ul>
                    <li>
                      <Link className="strikethrough" to="/work/nubi">
                        <Trans>NUBI Case Study</Trans>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </StyledService>
          </div>

          <div className="col-12 col-sm-10 offset-sm-1">
            <StyledService>
              <div className="service__icon">
                <Lottie animation={Frontend} />
              </div>
              <div className="service__content">
                <h3>
                  <Trans>Front end development</Trans>
                </h3>
                <br />
                <p>
                  <Trans>
                    We have in-house development talent to implement designs.
                    For larger projects, we work with trusted partners and
                    back-end teams to build websites and apps.
                  </Trans>
                </p>
                <br />
                <div className="more-info">
                  <p className="paragraph--small">
                    <Trans>View this service in action:</Trans>
                  </p>
                  <ul>
                    <li>
                      <Link className="strikethrough" to="/work/samsung">
                        <Trans>Samsung Case Study</Trans>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </StyledService>
          </div>
        </div>
      </Container>
    </StyledWhatWeDo>
  )
}

export default WhatWeDo
