import React from "react"

// Components
import PageHero from "components/page-hero/"
import { useTranslation } from "react-i18next"

const Hero = () => {
  const { t } = useTranslation()
  return (
    <PageHero
      title={t(
        "A uniquely collaborative approach to solving design challenges."
      )}
      highlight={t("Together.")}
    />
  )
}

export default Hero
